.listItem {
    padding: theme('spacing.025') theme('spacing.050');
    border-radius: theme('borderRadius.050');
}

.discount {
    margin: theme('spacing.025') theme('spacing.050');
}

.link {
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    width: 100%;
    padding: theme('spacing.075') theme('spacing.100');
    outline-offset: calc(theme('spacing.050') * -1);
    border-radius: theme('borderRadius.050');
    transition-duration: theme('transitionDuration.fast');
    transition-property: background, color;
    transition-timing-function: theme('transitionTimingFunction.default');
    cursor: pointer;

    &:hover,
    &:focus {
        background: theme('sidebarNavigationItem.hover.background');
        color: theme('colors.actions.secondary');
        outline: none;
    }
}

.subNav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: theme('topNavigation.background');
    transform: translateX(100%);
    transition: transform theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    overflow: hidden;
}

.subNavOpen {
    transform: translateX(0);
    z-index: 1;
}

.overflowIndicator {
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -1px;
        display: block;
        width: calc(100% + 1px);
        height: 3rem;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(159,159,159,1) 100%);
        pointer-events: none;
    }

    & > ul {
        padding-bottom: 3rem;
    }
}
