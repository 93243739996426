.logOut {
    @apply type-090;

    display: flex;
    gap: theme('spacing.050');
    align-items: center;
    width: 100%;
    padding: theme('spacing.075') theme('spacing.100');
    border-radius: theme('borderRadius.050');
    transition-duration: theme('transitionDuration.fast');
    transition-property: background, color;
    transition-timing-function: theme('transitionTimingFunction.default');

    &:hover,
    &:focus {
        background: theme('sidebarNavigationItem.hover.background');
        color: theme('colors.actions.secondary');
        outline: none;
    }
}
