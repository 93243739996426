.nav {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 19.625rem;
    height: 100dvh;
    border-radius: theme('card.borderRadius.large') 0 0 theme('card.borderRadius.large');
    background-color: theme('topNavigation.background');
    color: theme('topNavigationLink.default.textColor');
    box-shadow: theme('boxShadow.default');
    z-index: theme('zIndex.flyout');
    overflow: hidden;
}

.navItems {
    @apply type-090;

    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}
