.banner {
    @apply before-bg-layers-canvas;

    position: relative;
    background-color: theme('banner.promo.background');
    z-index: 450;

    @media (min-width: theme('screens.lg')) {
        z-index: 2;
    }
}

.list {
    @apply grid-cols-1 type-090;

    position: relative;
    display: grid;
    gap: theme('banner.sizes.small');
    max-width: theme('container.sizes.xlarge');
    width: 100%;
    margin: 0 auto;
    padding: theme('banner.sizes.small');
    overflow-x: hidden;

    & > li {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: theme('spacing.075');
        align-items: center;
        justify-content: center;
        grid-area: 1 / 1;
        align-self: center;
        text-align: center;

        & > div {
            display: flex;
            gap: theme('spacing.075');
            align-items: center;
            justify-self: center;
        }
    }
}

.tabletGrid {
    grid-area: 1 / 1;

    & + li {
        position: relative;
        grid-area: 1 / 2;
    }
}
