.callout {
    padding: theme("padding.050") theme("padding.050");
    border-radius: theme("borderRadius.200");

    @media (min-width: theme('screens.sm')) {
        padding: theme("padding.100");
    }

    .calloutInner {
        display: flex;
        flex-direction: column;
        gap: theme("gap.200");
        padding: theme("padding.200") theme("padding.100");
        border-radius: theme("borderRadius.200");

        @media (min-width: theme('screens.sm')) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: theme("padding.200") theme("padding.150");
        }
    }
}

.calloutWhite {
    background-color: theme("colors.layers.foreground");
    border: 1px solid theme("colors.layers.highlight");

    .calloutInner {
        border: 1px solid theme("colors.decorators.accent");
    }
}

.calloutBlue {
    background-color: theme("colors.layers.highlight");
    border: 1px solid theme("colors.layers.highlight");

    .calloutInner {
        border: 1px solid theme("colors.decorators.strong");
    }
}

.calloutNavy {
    background-color: theme("colors.layers.soft");
    border: 1px solid theme("colors.layers.soft");

    .calloutInner {
        border: 1px solid theme("colors.decorators.muted");
        color: theme("colors.neutrals.white");
    }
}

.calloutContent {
    display: grid;
    grid-template-columns: 32px 1fr;
    column-gap: theme("gap.100");
    row-gap: theme("gap.050");
}