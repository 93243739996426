.background {
    position: relative;
    width: 0;
    background: theme('topNavigation.background');
    opacity: 0;
    transition-duration: theme('transitionDuration.fast');
    transition-property: opacity, width;
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 1px;
        height: 100%;
        background: theme('colors.decorators.subtle');
    }
}

.backgroundOpen {
    width: var(--sub-nav-width);
    opacity: 1;
}
