.discountDefault {
    display: block;
    padding: theme('spacing.025');
    border-radius: theme('borderRadius.050');
    background: theme('colors.products.promotion');

    & > span {
        display: flex;
        align-items: center;
        gap: theme('spacing.050');
        padding: theme('spacing.050');
        border: 1px solid theme('tag.discountCode.color');
        border-radius: theme('borderRadius.050');
    }
}


.discountLarge {
    display: block;
    padding: theme('spacing.050');
    border-radius: theme('borderRadius.050');
    background: theme('colors.products.promotion');

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: theme('spacing.050');
        padding: theme('spacing.050');
        border: 1px solid theme('tag.discountCode.color');
        border-radius: theme('borderRadius.050');
    }   
}