.dropDown {
    width: var(--sub-nav-width);
    transition-duration: theme('transitionDuration.fast');
    transition-property: opacity, border-radius;
    transition-timing-function: linear;
    opacity: 0;
    pointer-events: none;
}

.dropDownOpen {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
}

.firstDropDown {
    position: relative;
    background: theme('topNavigation.background');
}

.floatingDropDown {
    position: absolute;
    top: 0;
    height: 100%;
    left: calc(var(--depth) * var(--sub-nav-width));
}

.overflowIndicator {
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5rem;
        opacity: 0.5;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 26.05%, #C6C6C6 100%);
        pointer-events: none;
    }

    & > ul {
        padding-bottom: 3.5rem;
    }
}

.list {
    @apply space-y-050;

    max-height: min(60dvh, 35.5rem);
    padding: theme('spacing.050');
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
}
