.nav {
    @apply type-090;

    --sub-nav-width: 15.625rem;

    position: relative;
    border-bottom: 1px solid theme('colors.layers.canvas');
    background-color: theme('topNavigation.background');
    color: theme('topNavigationLink.default.textColor');
    z-index: 10;
}

.list {
    display: flex;
    align-items: center;
    max-width: theme('container.sizes.large');
    margin: 0 auto;
}
