.dropDowns {
    position: absolute;
    top: 100%;
    left: var(--starting-position);
    display: flex;
    padding-top: 1px;
    border-bottom-left-radius: theme('borderRadius.100');
    border-bottom-right-radius: theme('borderRadius.100');
    opacity: 0;
    box-shadow: theme('boxShadow.default');
    transition: opacity theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    pointer-events: none;
    overflow: hidden;
}

.dropDownsOpen {
    opacity: 1;
    pointer-events: all;
}
