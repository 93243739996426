.MixedItemsGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    grid-auto-flow: dense;
    gap: theme('spacing.100');

    @media (min-width:theme('container.sizes.small')) {
        grid-template-columns: repeat(4, minmax(0,1fr));
    }

    @media (min-width:theme('screens.lg')) {
        grid-template-columns: repeat(6, minmax(0,1fr));
    }
}

.MixedItemsGridSmallItem {
   grid-column: span 1;
}

.MixedItemsGridLargeItem {
   grid-column: span 2;
}

.MicroConditionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
    gap: theme('spacing.100');
}

.FeaturedGuidesGrid {
    @media (min-width:theme('screens.md')) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));
        gap: theme('spacing.100');
    }
    @media (min-width:theme('screens.lg')) {
        grid-template-columns: repeat(6, minmax(0,1fr));
    }

    & li:has([data-size=small]) {
        @media (min-width: theme('screens.lg')) {
            grid-column: span 2;
        }
    }
    & li:has([data-size=medium]) {
        @media (min-width: theme('screens.lg')) {
           grid-column: span 3;
        }
    }
    & li:has([data-size=large]) {
        @media (min-width: theme('screens.md')) {
            grid-column: span 2;
        }

        @media (min-width: theme('screens.lg')) {
            grid-column: span 6;
        }
    }
}

.guide {
    width: 20rem;
    height: 100%;

    @media (min-width: theme('screens.md')) {
        width: auto;
    }
}

.guideLarge {
    @media (min-width: theme('screens.md')) {
        min-width: 15rem;
        flex-basis: 15rem;
    }
}

.guideMedium {
    @media (min-width: theme('screens.md')) {
        min-width: 15rem;
        flex-basis: 15rem;
    }
}

.guideSmall {
    @media (min-width: theme('screens.md')) {
        min-width: 15rem;
        flex-basis: 15rem;
    }
}

.orderingIsEasy {
    & > div {
        padding-bottom: 17.25rem;

        @media (min-width: theme('screens.md')) {
            padding-bottom: 10rem;
        }

        @media (min-width: theme('screens.lg')) {
            padding-bottom: theme('spacing.600');
        }
    }
}

.trustBlock {
    margin-top: -15.5rem;

    @media (min-width: theme('screens.md')) {
        margin-top: -8rem;
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: -5.25rem;
    }
}

.exitConditionCallout:last-child {
    margin-bottom: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-bottom: theme('spacing.rhythm-medium');
    }
}
