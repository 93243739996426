.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: theme('spacing.150') theme('spacing.100');
}

.button {
    display: flex;
    align-items: center;
    text-align: left;

    & img {
        padding: theme('spacing.050');
        box-sizing: content-box;
        opacity: 0;
        transform: translateY(theme('spacing.025'));
        transition-duration: theme('transitionDuration.fast');
        transition-property: opacity, transform;
        transition-timing-function: theme('transitionTimingFunction.default');
    }
}

.title {
    display: grid;
    transform: translateX(-2rem);
    transition: transform theme('transitionDuration.fast') theme('transitionTimingFunction.default');

    & > span {
        grid-area: 1 / 1;
        transition-duration: theme('transitionDuration.fast');
        transition-property: opacity, transform;
        transition-timing-function: theme('transitionTimingFunction.default');
    }
}

.enter {
  opacity: 0;
}
.enterActive {
  opacity: 1;
}
.exit {
  opacity: 1;
}
.exitActive {
  opacity: 0;
}

.backButtonAnimateIn {
    & img {
        opacity: 1;
        transform: translateY(0);
    }

    & .title {
        transform: translateX(0);
    }
}
