.listItem {
    position: relative;
    transition: opacity theme('transitionDuration.fast') theme('transitionTimingFunction.default');
}

.link {
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    width: 100%;
    padding: theme('spacing.075') theme('spacing.100');
    outline-offset: calc(theme('spacing.050') * -1);
    border-radius: theme('borderRadius.050');
    transition-duration: theme('transitionDuration.fast');
    transition-property: color, background;
    transition-timing-function: theme('transitionTimingFunction.default');
    cursor: pointer;

    &:hover,
    &:focus {
        background: theme('sidebarNavigationItem.hover.background');
        color: theme('colors.actions.secondary');
    }
}

.linkActive {
    background: theme('sidebarNavigationItem.hover.background');
}
