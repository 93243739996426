.header {
    position: relative;
    min-height: 5.0625rem;
    padding: theme('siteHeader.padding');
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: theme('siteHeader.background');
    border-radius: 0 0 theme('siteHeader.borderRadius') theme('siteHeader.borderRadius');
    background-color: theme('siteHeader.background');
    transition-property: border-color, transform;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.default');
    z-index: theme('zIndex.header');
}

.headerSticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;

    @media (min-width: theme('screens.lg')) {
        position: relative;
    }
}

.stickyHeaderPlaceholder {
    min-height: 5.0625rem;
    background-color: theme('siteHeader.background');

    @media (min-width: theme('screens.md')) {
        height: 81px;
    }

    @media (min-width: theme('screens.lg')) {
        display: none;
    }
}

.container {
    position: relative;
    display: flex;
    row-gap: theme('spacing.100');
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: theme('screens.md')) {
        column-gap:  theme('spacing.150');
    }
}

.logo {
    flex-shrink: 0;
    border-radius: theme('siteHeaderLink.default.borderRadius');
    order: 1;

    @media (min-width: theme('screens.md')) {
        order: 0;
    }

    &:focus {
        outline: 2px solid theme('colors.layers.strong');
        outline-offset: theme('spacing.0125');
    }

    img {
        width: 6.25rem;

        @media (min-width: 365px) {
            width: 7.5rem;
        }
    }
}

.search {
    width: 100%;
    order: 3;

    @media (min-width: theme('screens.md')) {
        @apply -mr-100;

        display: block;
        order: 0;
        flex-grow: 0;
        width: 15rem;
        transition: flex-grow theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    }

    @media (min-width: theme('screens.lg')) {
        width: 19.75rem;
    }

    &:focus-within {
        flex-grow: 1;
    }
}

.links {
    display: flex;
    flex-shrink: 0;
    order: 2;
    margin-left: auto;

    @media (min-width: theme('screens.md')) {
        order: 0;
    }

}

.link {
    @apply type-emphasis type-100 leading-200;

    display: flex;
    align-items: center;
    gap: theme('siteHeaderLink.spacing');
    padding: theme('siteHeaderLink.padding');
    border-radius: theme('siteHeaderLink.default.borderRadius');
    background: theme('siteHeaderLink.default.background');
    transition-properties: background;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.default');
    font-family: theme('fontFamily.main');
    font-weight: theme('fontWeight.700');
    color: theme('siteHeaderLink.default.textColor');

    &:hover {
        background: theme('siteHeaderLink.hover.background');
    }

    &:focus {
        outline: 2px solid theme('colors.layers.strong');
    }
}

.signedInIcon {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        width: theme('spacing.075');
        height: theme('spacing.075');
        border: 2px solid theme('colors.content.foundation');
        border-radius: 100%;
        background-color: theme('colors.status.success.default');
    }
}

.headerScrolled {
    border-color: #435289;
}
