.listItemDefault {
    padding: theme('spacing.025');
    border-radius: theme('borderRadius.050');
    background: theme('colors.layers.soft');
    color: theme('colors.neutrals.white');
}

.listItemLarge {
    padding: theme('spacing.050');
    border-radius: theme('borderRadius.050');
    background: theme('colors.layers.soft');
    color: theme('colors.neutrals.white');
}

.linkDefault {
    display: flex;
    align-items: center;
    padding: theme('spacing.050');
    border: 1px solid theme('colors.decorators.muted');
    border-radius: theme('borderRadius.050');
}

.linkLarge {
    display: flex;
    align-items: center;
    padding: theme('spacing.050');
    border: 1px solid theme('colors.decorators.muted');
    border-radius: theme('borderRadius.050');
}
