.listItem {
    position: relative;

    &:hover,
    &:focus-within {
        background: theme('sidebarNavigationItem.hover.background');

        & .nav {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.link {
    display: flex;
    align-items: center;
    gap: theme('spacing.050');
    width: 100%;
    padding: theme('spacing.100') theme('spacing.150');
    outline-offset: calc(theme('spacing.050') * -1);
    cursor: pointer;
}
