.actions {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.075');
    align-items: flex-end;

    @media (min-width: 465px) {
        flex-direction: row;
        gap: theme('spacing.100');
        align-items: center;
        justify-content: flex-end;
    }

    @media (min-width: 730px) {
        gap: theme('spacing.150');
    }

    & p {
        max-width: 17.75rem;
        text-align: right;

        @media (min-width: 730px) {
            max-width: none;
        }
    }
}